<template>
  <div class="bigPage">
    <div class="page">
      <div class="header">
        <div class="head">
          <img v-if="this.img != ''" class="headImg" :src="this.img" alt="">
        </div>
        <div class="goodTitBox">
          <div class="title1">
            <p class="nowPrice" v-if="is_hide_price==0"><i>¥</i>{{price}}<span>/{{unit}}</span></p>
            <p class="beforePrice" v-if="is_hide_price==0">¥{{source_price}}</p>
          </div>
          <div class="title2">{{goods_title}}</div>
          <div class="title3">{{sub_title}}</div>
          <div class="title4" v-if="this.fuwu.length>0">
            <p v-for="item in this.fuwu">
              <img :src="imgUrl+item.icon" alt="">
              <span>{{item.title}}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="store">
          <span class="storeImg"><img v-if="this.shop_logo != ''" :src="this.shop_logo" alt=""></span>
          <span class="storeName">{{shop_title}}</span>
        </div>
        <div class="info">
          <p class="canshu">商品参数</p>
          <p class="canshuInfo"><span>产地</span><b>见产品外包装</b></p>
          <p class="canshuInfo" v-for="item in this.labels">
            <span>{{item.label}}</span>
            <b>{{item.cont==''?'暂无':item.cont}}</b>
          </p>
          <p class="canshuInfo"><span>储存条件…</span><b>见产品外包装</b></p>
        </div>
      </div>
      <div class="tips" v-if="this.intro!=''">
        {{intro}}
      </div>
      <div class="footer" v-html="this.content"></div>
    </div>
  </div>
</template>

<script>
import { GetGoodsInfo } from "@/api/bill";
import qs from "qs";
export default {
  name: "goodsDetail",
  data() {
    return {
      imgUrl:this.GLOBAL.imgUrl,
      goods_code: "",
      content:'',
      goods_title:'',
      sub_title:'',
      guige:'',
      img:'',
      intro:'',
      fuwu:[],
      labels:[],
      price:'',
      source_price:'',
      sales:'',
      shop_title:'',
      shop_logo:'',
      unit:'',
      is_hide_price:0,
    };
  },
  mounted: function () {
    this.getGoodsInfo();
    document.title = '商品详情'
  },
  methods: {
    isDeviceMobile(){
      var u = navigator.userAgent
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isiOS || isAndroid) {
        // alert('移动端')
        return true
      } else {
        // alert('PC端')
        return false
      }
    },
    getGoodsInfo(){
      this.goods_code = this.$route.query.goods_code;
      let params = {
        goods_code: this.goods_code,
      };
      GetGoodsInfo(qs.stringify(params)).then((res) => {
        let { code, data, message } = res;
        if(code == 0){
          this.img = this.imgUrl+data.base.img;
          this.content = data.base.content;
          this.price = data.base.price;
          this.source_price = data.base.source_price;
          this.unit = data.base.unit;
          this.goods_title = data.base.goods_title;
          this.sub_title = data.base.sub_title;
          this.fuwu = data.base.fuwu
          this.shop_title = data.base.shop_title
          this.shop_logo = this.imgUrl+data.base.shop_logo
          this.labels = data.base.labels
          this.intro = data.base.intro
          this.is_hide_price = data.base.is_hide_price
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .bigPage {
    width: 320px;
    margin: 0 auto;
  }
}
.header{
  background-color: #FFF;
  .head{
    text-align: center;
    padding: 10px;
    .headImg{
      width: 100%;
    }
  }
  .goodTitBox{
    padding: 20px 16px;
    .title1{
      display: flex;
      line-height: 28px;
      .nowPrice{
        margin: 0;
        color: #FF3737;
        font-size: 28px;
        font-weight: 600;
        i{
          font-size: 18px;
          font-style: normal;
          font-family: PingFangSC-Medium;
          font-weight: 600;
        }
        span{
          color: #BBBBBB;
          font-size: 14px;
        }
      }
      .beforePrice{
        font-size: 14px;
        text-decoration: line-through;
        color: #BBBBBB;
        margin: 5px 0 0 16px;
      }
    }
    .title2{
      font-size: 18px;
      font-weight: 600;
      color: #111111;
      margin-top: 16px;
    }
    .title3{
      font-size: 14px;
      color: #909090;
      margin-top: 12px;
    }
    .title4{
      display: flex;
      flex-wrap:wrap;
      font-size: 13px;
      color: #909090;
      margin-top: 16px;
      p{

        margin: 5px 0;
        img{
          width: 18px;
          height: 18px;
          vertical-align: text-top;
        }
        span{
          margin-left: 4px;
        }
      }
      p:not(:nth-child(4n-1)){
        margin-right: 16px;
      }
    }
  }
}
.content{
  background-color: #F3F3F3;
  padding: 8px 12px;
  .store{
    background-color: #FFF;
    padding: 12px;
    border-radius: 12px;
    display: flex;
    .storeImg{
      display: block;
      width: 30px;
      height: 30px;
      padding: 2px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #D8D8D8;
      img{
        width: 27px;
        height: 27px;
      }
    }
    .storeName{
      font-size: 13px;
      color: #000000;
      margin-left: 12px;
      display: block;
      margin-top: 9px;
    }
  }
  .info{
    margin-top: 8px;
    background-color: #FFF;
    padding: 16px 12px;
    border-radius: 12px;
    .canshu{
      font-size: 14px;
      font-weight: 600;
      color: #373737;
    }
    .canshuInfo{
      display: flex;
      margin-top: 20px;
      span{
        display: block;
        min-width: 100px;
        max-width: 200px;
        font-size: 14px;
        font-weight: 400;
        color: #BBBBBB;
      }
      b{
        display: block;
        min-width: 100px;
        max-width: 150px;
        font-size: 14px;
        font-weight: 400;
        color: #373737;
      }
    }
  }
}
.tips{
  background-color: #FFF;
  padding: 16px;
  font-size: 14px;
  color: #F30000;
  p{
    margin: 0;
    line-height: 26px;
  }
}
.footer{
  width: 100%;
  margin: 0 auto;
  p{
    span{
      background-color: #FFF !important;
      padding: 16px !important;
      font-size: 14px !important;
      color: #F30000 !important;
    }
    img{
      margin: 0 auto !important;
    }
  }
  img{
    width: 100%;
    margin: 0 auto;
  }
}
.open21du{
  background-color:#02B2FF;
  line-height:40px;
  height:40px;
  padding: 0 8px 0 16px;
  color: #FFF;
  font-size:15px;
  border-radius: 100px 0px 0px 100px;
  img{
    width:14px;
    vertical-align:middle;
    margin-bottom:4px;
    margin-left: 3px;
  }
}
</style>